// 测试
// export const SERVER_URL = "https://tgwapi.campcenter.cn/modules-campsite"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "https://tgwapi.campcenter.cn/modules-campsite"; //开发环境
// export const SERVER_WS_URL = "wss://tgwapi.campcenter.cn"; // websocket

// 正式
export const SERVER_URL = "https://gwapi.campcenter.cn/modules-campsite"; // 正式环境
export const DEVELOPMENT_SERVER_URL = "https://gwapi.campcenter.cn/modules-campsite"; //开发环境
export const SERVER_WS_URL = "wss://gwapi.campcenter.cn"; // websocket

// 测试
// export const SERVER_URL = "http://192.168.1.146:8088/modules-campsite"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "http://192.168.1.146:8088/modules-campsite"; //开发环境
// export const SERVER_WS_URL = "wss://192.168.1.146:8088"; // websocket
